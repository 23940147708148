import FormData from 'form-data';
import Mailgun from 'mailgun.js';

const MAILGUN_API_KEY = import.meta.env.VITE_MAILGUN_API_KEY;
const MAILGUN_DOMAIN = import.meta.env.VITE_MAILGUN_DOMAIN;

if (!MAILGUN_API_KEY || !MAILGUN_DOMAIN) {
  throw new Error('Mailgun configuration is required');
}

const mailgun = new Mailgun(FormData);
const client = mailgun.client({
  username: 'api',
  key: MAILGUN_API_KEY,
  url: 'https://api.mailgun.net'
});

export const sendWelcomeEmail = async (email: string): Promise<boolean> => {
  const messageData = {
    from: `CarInspect AI <no-reply@${MAILGUN_DOMAIN}>`,
    to: email,
    subject: 'Welcome to CarInspect AI Early Access Program',
    html: `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
        <h1 style="color: #2563eb; margin-bottom: 24px;">Welcome to CarInspect AI!</h1>
        
        <p style="color: #4b5563; font-size: 16px; line-height: 1.6;">
          Thank you for joining our Early Access program! We're excited to have you on board.
        </p>
        
        <p style="color: #4b5563; font-size: 16px; line-height: 1.6;">
          We're working hard to revolutionize the car damage assessment process, and we'll be in touch soon with more information about our platform and your early access privileges.
        </p>
        
        <p style="color: #4b5563; font-size: 16px; line-height: 1.6;">
          In the meantime, if you have any questions, feel free to reach out to our support team.
        </p>
        
        <div style="margin-top: 32px; padding-top: 24px; border-top: 1px solid #e5e7eb;">
          <p style="color: #6b7280; font-size: 14px;">
            Best regards,<br>
            The CarInspect AI Team
          </p>
        </div>
      </div>
    `
  };

  try {
    if (!client.messages) {
      throw new Error('Mailgun client messages not initialized');
    }

    await client.messages.create(MAILGUN_DOMAIN, messageData);
    return true;
  } catch (error) {
    console.error('Error sending welcome email:', error);
    if (error instanceof Error) {
      throw error;
    }
    throw new Error('Failed to send welcome email. Please try again later.');
  }
};