import { createClient } from 'contentful-management';
import { createClient as createContentfulClient } from 'contentful';
import { sendWelcomeEmail } from './email';

const getContentfulClient = () => {
  const accessToken = import.meta.env.VITE_CONTENTFUL_MANAGEMENT_TOKEN;
  const spaceId = import.meta.env.VITE_CONTENTFUL_SPACE_ID;

  if (!accessToken || !spaceId) {
    throw new Error('Missing required Contentful configuration. Please check your environment variables.');
  }

  return createClient({
    accessToken
  });
};

const getContentfulDeliveryClient = () => {
  const accessToken = import.meta.env.VITE_CONTENTFUL_ACCESS_TOKEN;
  const spaceId = import.meta.env.VITE_CONTENTFUL_SPACE_ID;

  if (!accessToken || !spaceId) {
    throw new Error('Missing required Contentful configuration');
  }

  return createContentfulClient({
    space: spaceId,
    accessToken: accessToken,
  });
};

export const getSiteVideo = async () => {
  const client = getContentfulDeliveryClient();
  const entry = await client.getEntries({
    content_type: 'siteMedia',
    'fields.name': 'DamageAssessmentVideo',
    limit: 1
  });

  if (entry.items.length === 0) {
    throw new Error('Video not found');
  }

  return entry.items[0].fields.video.fields.file.url;
};

export const addEmailToContentful = async (email: string) => {
  const client = getContentfulClient();
  const space = await client.getSpace(import.meta.env.VITE_CONTENTFUL_SPACE_ID);
  
  // Get the environment
  const environment = await space.getEnvironment(import.meta.env.VITE_CONTENTFUL_ENVIRONMENT || 'master');
  
  // Check if content type exists
  let contentType;
  try {
    contentType = await environment.getContentType('earlyAccessEmails');
  } catch (error) {
    // Create content type if it doesn't exist
    contentType = await environment.createContentTypeWithId('earlyAccessEmails', {
      name: 'Early Access Emails',
      fields: [
        {
          id: 'email',
          name: 'Email',
          type: 'Text',
          required: true
        },
        {
          id: 'signupDate',
          name: 'Signup Date',
          type: 'Date',
          required: true
        }
      ]
    });
    await contentType.publish();
  }
  
  // Create the entry
  const entry = await environment.createEntry('earlyAccessEmails', {
    fields: {
      email: {
        'en-US': email
      },
      signupDate: {
        'en-US': new Date().toISOString()
      }
    }
  });

  // Publish the entry
  await entry.publish();
  
  // Send welcome email
  await sendWelcomeEmail(email);
  
  return true;
};