import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Camera, Clock, DollarSign, Shield, ChevronRight, Camera as CameraIcon, CheckCircle } from 'lucide-react';
import { EarlyAccessForm } from './components/EarlyAccessForm';
import { CookieConsent } from './components/CookieConsent';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { getSiteVideo } from './lib/contentful';

function App() {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string>('');
  const videoRef = useRef<HTMLVideoElement>(null);
  const earlyAccessRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadVideo = async () => {
      try {
        const url = await getSiteVideo();
        setVideoUrl(url);
      } catch (error) {
        console.error('Failed to load video:', error);
      }
    };
    loadVideo();
  }, []);

  const scrollToEarlyAccess = () => {
    earlyAccessRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  if (showPrivacyPolicy) {
    return <PrivacyPolicy onClose={() => setShowPrivacyPolicy(false)} />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white relative">
      <Helmet>
        <title>AI based Car Damage Assessment - CarInspect AI</title>
        <meta name="description" content="AI-powered car damage assessment platform. Get instant, accurate damage evaluations and claim estimates using advanced artificial intelligence technology." />
        <link rel="canonical" href="https://carinspect.io" />
      </Helmet>

      {/* Hero Section */}
      <header className="container mx-auto px-4 py-16 md:py-24">
        <div className="flex flex-col md:flex-row items-center justify-between gap-12">
          <div className="flex-1 space-y-6">
            <div className="inline-block px-4 py-2 bg-blue-50 rounded-full">
              <p className="text-blue-600 font-medium text-sm">AI-Powered Insurance Claims</p>
            </div>
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900">
              Instant Car Damage Assessment with CarInspect AI
            </h1>
            <p className="text-xl text-gray-600">
              Get accurate damage assessments and instant claim estimates using our advanced AI technology. 
              Process claims 10x faster while reducing operational costs.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <button 
                onClick={scrollToEarlyAccess}
                className="px-6 py-3 bg-blue-600 text-white rounded-lg font-semibold hover:bg-blue-700 transition flex items-center justify-center gap-2"
              >
                Start Free Trial <ChevronRight size={20} />
              </button>
            </div>
            <div className="flex gap-6 pt-4">
              <div className="flex items-center gap-2">
                <CheckCircle className="text-green-500" size={20} />
                <span className="text-sm text-gray-600">No credit card required</span>
              </div>
              <div className="flex items-center gap-2">
                <CheckCircle className="text-green-500" size={20} />
                <span className="text-sm text-gray-600">Instant results</span>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="rounded-lg shadow-2xl overflow-hidden bg-gray-900">
              {videoUrl && (
                <video 
                  ref={videoRef}
                  className="w-full h-full object-cover"
                  autoPlay 
                  loop 
                  muted 
                  playsInline
                  controls
                  preload="metadata"
                >
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </div>
        </div>
      </header>

      {/* Value Proposition Section */}
      <section className="bg-blue-50 py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center space-y-4">
            <h2 className="text-3xl font-bold text-gray-900">Revolutionizing Vehicle Damage Assessment</h2>
            <p className="text-xl text-gray-600">
              CarInspect is an AI-powered Insurance Claim Assessment software that revolutionizes the way vehicle damage is evaluated. 
              Our advanced AI model instantly detects damaged parts after an accident, categorizes whether they require repair or replacement, 
              and provides an instant claim estimate—eliminating delays and uncertainty.
            </p>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="bg-white py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">How CarInspect Works</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="p-6 rounded-xl bg-gray-50">
              <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4">
                <CameraIcon className="text-blue-600" size={24} />
              </div>
              <h3 className="text-xl font-semibold mb-2">Capture Damage</h3>
              <p className="text-gray-600">Take photos of the vehicle damage using your smartphone</p>
            </div>
            <div className="p-6 rounded-xl bg-gray-50">
              <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4">
                <Clock className="text-blue-600" size={24} />
              </div>
              <h3 className="text-xl font-semibold mb-2">AI Analysis</h3>
              <p className="text-gray-600">Our AI instantly identifies damaged parts and required actions</p>
            </div>
            <div className="p-6 rounded-xl bg-gray-50">
              <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4">
                <DollarSign className="text-blue-600" size={24} />
              </div>
              <h3 className="text-xl font-semibold mb-2">Instant Estimate</h3>
              <p className="text-gray-600">Get immediate claim estimates and processing recommendations</p>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="bg-gray-50 py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">Benefits for Everyone</h2>
            <p className="text-xl text-gray-600">
              We help policyholders get their claims processed faster and with ease, while insurance companies 
              reduce operational costs and save 10x the time spent on manual claim assessment.
            </p>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section ref={earlyAccessRef} className="py-20 bg-blue-50">
        <div className="container mx-auto px-4 text-center">
          <div className="max-w-2xl mx-auto space-y-6">
            <Shield className="w-16 h-16 text-blue-600 mx-auto" />
            <h2 className="text-3xl font-bold">Ready to Transform Your Claims Process?</h2>
            <p className="text-xl text-gray-600">
              Join our early access program and experience seamless, accurate, and efficient claim processing
            </p>
            <EarlyAccessForm />
            <p className="text-sm text-gray-500">
              Free trial available. No credit card required.
            </p>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-50 py-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
            <div className="flex items-center gap-4">
              <p className="text-gray-600">© 2025 CarInspect AI. All rights reserved.</p>
              <a 
                href="https://codulate.com" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-blue-600 hover:text-blue-700 transition"
              >
                Built by Codulate
              </a>
            </div>
            <button
              onClick={() => setShowPrivacyPolicy(true)}
              className="text-gray-600 hover:text-gray-900 transition"
            >
              Privacy Policy
            </button>
          </div>
        </div>
      </footer>

      {/* Cookie Consent Banner */}
      <CookieConsent />
    </div>
  );
}

export default App;