import React from 'react';
import { ArrowLeft } from 'lucide-react';

export const PrivacyPolicy: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="container mx-auto px-4 max-w-4xl">
        <button
          onClick={onClose}
          className="mb-8 flex items-center gap-2 text-gray-600 hover:text-gray-900 transition"
        >
          <ArrowLeft size={20} />
          Back to Home
        </button>
        
        <div className="bg-white rounded-lg shadow-md p-8 space-y-8">
          <h1 className="text-3xl font-bold text-gray-900">Privacy Policy</h1>
          
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-gray-900">1. Introduction</h2>
            <p className="text-gray-600">
              At CarInspect AI, we take your privacy seriously. This Privacy Policy explains how we collect, 
              use, disclose, and safeguard your information when you visit our website and use our services.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-gray-900">2. Information We Collect</h2>
            <div className="space-y-2">
              <h3 className="text-xl font-medium text-gray-900">2.1 Personal Information</h3>
              <p className="text-gray-600">
                We collect information that you voluntarily provide to us when you:
              </p>
              <ul className="list-disc list-inside text-gray-600 ml-4">
                <li>Sign up for our Early Access program</li>
                <li>Create an account</li>
                <li>Contact us for support</li>
                <li>Subscribe to our newsletter</li>
              </ul>
            </div>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-gray-900">3. How We Use Your Information</h2>
            <p className="text-gray-600">
              We use the information we collect to:
            </p>
            <ul className="list-disc list-inside text-gray-600 ml-4">
              <li>Provide and maintain our services</li>
              <li>Send you updates about our Early Access program</li>
              <li>Improve our website and services</li>
              <li>Respond to your inquiries and support requests</li>
            </ul>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-gray-900">4. Cookies</h2>
            <p className="text-gray-600">
              We use cookies and similar tracking technologies to track activity on our website and store 
              certain information. Cookies are files with small amount of data which may include an anonymous 
              unique identifier. You can instruct your browser to refuse all cookies or to indicate when a 
              cookie is being sent.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-gray-900">5. Data Security</h2>
            <p className="text-gray-600">
              We implement appropriate technical and organizational security measures to protect your personal 
              information. However, please note that no method of transmission over the Internet or method of 
              electronic storage is 100% secure.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-gray-900">6. Contact Us</h2>
            <p className="text-gray-600">
              If you have any questions about this Privacy Policy, please contact us at privacy@carinspect.ai
            </p>
          </section>

          <div className="text-sm text-gray-500 pt-8">
            Last updated: March 1, 2025
          </div>
        </div>
      </div>
    </div>
  );
};